@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('./../components/core/Button/button.styles.scss');
@import url('./../components/core/Tag/tag.styles.scss');
@import url('./../components/core/Input/input.styles.scss');
@import url('./../components/core/BlinkingDots/blinkingdots.styles.scss');

@layer base {
	:root {
		--color-0: theme(colors.light.0);
		--color-1: theme(colors.light.1);
		--color-2: theme(colors.light.2);
		--color-3: theme(colors.light.3);
		--color-4: theme(colors.light.4);
		--color-5: theme(colors.light.5);
		--color-6: theme(colors.light.6);
		--color-7: theme(colors.light.7);
		--color-8: theme(colors.light.8);
		--color-9: theme(colors.light.9);
	}

	.dark {
		--color-0: theme(colors.dark.0);
		--color-1: theme(colors.dark.1);
		--color-2: theme(colors.dark.2);
		--color-3: theme(colors.dark.3);
		--color-4: theme(colors.dark.4);
		--color-5: theme(colors.dark.5);
		--color-6: theme(colors.dark.6);
		--color-7: theme(colors.dark.7);
		--color-8: theme(colors.dark.8);
		--color-9: theme(colors.dark.9);
	}
}

.card-bg {
	@apply bg-light-1 dark:bg-darkCard;
}

.text-gradient-1 {
	&:hover {
		@apply bg-gradient1 text-transparent bg-clip-text;
	}
	@apply bg-gradient1 text-transparent bg-clip-text;
}

.text-gradient-2 {
	@apply bg-gradient2 text-transparent bg-clip-text;
}

.smooth-hover {
	@apply transition-colors ease-[cubic-bezier(1,1,1,1)] duration-300;
}
