@import 'antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;600;700&display=swap');
@import url('./tailwind.scss');

@font-face {
	font-family: 'Mint Grotesk';
	src: url('/fonts/MintGrotesk/MintGroteskTrial-Regular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Mint Grotesk';
	src: url('/fonts/MintGrotesk/MintGroteskTrial-Bold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Mint Grotesk';
	src: url('/fonts/MintGrotesk/MintGroteskTrial-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Mint Grotesk';
	src: url('/fonts/MintGrotesk/MintGroteskItalicTrial-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Mint Grotesk';
	src: url('/fonts/MintGrotesk/MintGroteskTrials-RegularItalic.otf') format('opentype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Crack Grotesk';
	src: url('/fonts/CrackGrotesk/CrackGroteskTrials-Regular.ttf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Crack Grotesk';
	src: url('/fonts/CrackGrotesk/CrackGroteskTrials-Bold.ttf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Crack Grotesk';
	src: url('/fonts/CrackGrotesk/CrackGroteskTrials-Medium.ttf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Crack Grotesk';
	src: url('/fonts/CrackGrotesk/CrackGroteskTrials-RegularItalic.ttf') format('opentype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'GT Flexa';
	src: url('/fonts/GT-Flexa/GT-Flexa-Medium-Trial.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'GT Flexa';
	src: url('/fonts/GT-Flexa/GT-Flexa-Regular-Italic-Trial.woff2') format('woff2');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'GT Flexa';
	src: url('/fonts/GT-Flexa/GT-Flexa-Regular-Trial.woff2') format('woff2');
	font-weight: medium;
	font-style: normal;
}

@font-face {
	font-family: 'GT Flexa';
	src: url('/fonts/GT-Flexa/GT-Flexa-Medium-Italic-Trial.woff2') format('woff2');
	font-weight: medium;
	font-style: italic;
}

@font-face {
	font-family: 'GT Flexa';
	src: url('/fonts/GT-Flexa/GT-Flexa-Bold-Trial.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'GT Flexa';
	src: url('/fonts/GT-Flexa/GT-Flexa-Bold-Italic-Trial.woff2') format('woff2');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'GT Flexa Extended';
	src: url('/fonts/GT-Flexa/GT-Flexa-Extended-Regular-Trial.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'GT Flexa Extended';
	src: url('/fonts/GT-Flexa/GT-Flexa-Extended-Regular-Italic-Trial.woff2') format('woff2');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'GT Flexa Extended';
	src: url('/fonts/GT-Flexa/GT-Flexa-Extended-Medium-Trial.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'GT Flexa Extended';
	src: url('/fonts/GT-Flexa/GT-Flexa-Extended-Medium-Italic-Trial.woff2') format('woff2');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Kaftan';
	src: url('/fonts/Kaftan/Kaftan Serif.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roobert';
	src: url('/fonts/Roobert/Roobert-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roobert';
	src: url('/fonts/Roobert/Roobert-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Roobert';
	src: url('/fonts/Roobert/Roobert-Heavy.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Roobert';
	src: url('/fonts/Roobert/Roobert-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Roobert';
	src: url('/fonts/Roobert/Roobert-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roobert';
	src: url('/fonts/Roobert/Roobert-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
html {
	background: var(--color-1);
}
body {
	font-family: 'Roobert', sans-serif;
	font-weight: 500;
	font-feature-settings: 'liga' 0, 'dlig' 0;
	@apply proportional-nums;
	@apply text-coindeskBlack #{!important};
}

.secondary-text {
	@apply text-deepgray dark:text-dark-6;
}

.gt-flexa {
	font-family: 'GT Flexa', sans-serif;
}

.gt-flexa-extended {
	font-family: 'GT Flexa Extended', sans-serif;
	font-weight: 400;
}

.kaftan {
	font-family: 'Kaftan';
}

.roobert {
	font-family: 'Roobert';
}

.ant-upload-list-item-name {
	max-width: 24ch;
}

.ant-upload-list-item-name {
	max-width: 24ch;
}

.ant-card-body {
	padding: 0 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roobert', sans-serif;
	font-weight: 500;
	// @apply dark:text-dark-8 #{!important};
}

p,
a,
input::placeholder {
	font-family: 'Roobert', sans-serif;
}

a {
	transition: none;
}

.ant-layout-header {
	background-color: transparent;
	height: max-content;
	padding-left: 0;
	padding-right: 0;
}

.user-card {
	/* filter: drop-shadow(10px 10px 100px #C4C4C4); */
	box-shadow: 10px 10px 80px #c4c4c4;
}

.ant-menu {
	background: transparent;
}

.ant-menu-horizontal {
	border-bottom: none;
}

.ant-layout-footer {
	margin-top: 60px;
	background: none;
	background-color: '#fdffff';
	padding: 10px 20px;
	padding-bottom: 0;
}
/* .ant-layout-footer a {
	color: black;
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
	margin-top: 15px;
	display: block;
} */

.ant-layout-footer .ant-btn-ghost {
	border: 1px solid #292929;
	color: #292929;
	text-transform: uppercase;
}

.ant-input,
.ant-input-number {
	border-radius: 20px;
}

.ant-input-number {
	width: 100%;
}

.nav-btn {
	/* color: rgb(61, 138, 253); */
	background: rgb(85, 153, 255, 0.2);
}

.ant-btn {
	outline: none;
	text-transform: none;
	color: rgb(61, 138, 253);
	background: rgb(85, 153, 255, 0.2);
	padding-left: 16px;
	padding-right: 16px;
	align-items: center;
	border: 0;

	/* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.ant-btn:hover {
	color: rgb(61, 138, 253);
	background: rgb(85, 153, 255, 0.5);
}

.address {
	color: #5c88f9;
	background: rgba(255, 255, 255, 1);
}

.ant-btn-link {
	color: #1890ff;
	background: transparent;
	border-color: transparent;
	box-shadow: none;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
	color: #1890ff;
	background: transparent;
	border: 1px solid #1890ff;
}

.ant-btn-ghost {
	background: transparent;
	border: 1px solid white;
}

.white-on-black,
.white-on-black a,
.white-on-black h1,
.white-on-black h2,
.white-on-black h3,
.white-on-black h4,
.white-on-black h5,
.white-on-black h6 {
	color: white;
}

.white-on-black a:hover {
	color: #f6ad55;
}

.white-on-black h2 {
	/* font-family: 'Bol', '-apple-system', 'BlinkMacSystemFont'; */
	font-family: 'Roobert', sans-serif;
	/* font-family: "Avd","-apple-system","BlinkMacSystemFont"; */
}

/* .ant-card.bordered {

} */

.ant-card-head-title,
.ant-form-item-label > label {
	/* font-family: 'Bol', '-apple-system', 'BlinkMacSystemFont'; */
	font-family: 'GT Flexa';
	font-weight: 500;
}

.header-btn {
	background: rgb(79, 70, 229);
	background: linear-gradient(90deg, rgba(79, 70, 229, 1) 10%, rgba(37, 99, 235, 1) 100%);
}

@media (min-width: 768px) {
	.profile-btn {
		width: 100px;
	}
}

.ant-table-thead > tr > th {
	text-transform: uppercase;
	background-color: black;
	color: white;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover {
	background-color: black;
	border-color: white;
}

.ant-pagination-item:hover {
	border-color: black;
}
.ant-pagination-item a:hover {
	color: black;
}

.ant-pagination-item-active a,
.ant-pagination-item-active a:hover {
	color: white;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
	color: black;
	border-color: black;
}

.ant-typography h3,
h3.ant-typography {
	font-size: 22px;
}

.large input {
	padding: 8px 0;
}

.ant-tabs-tab {
	font-family: 'GT Flexa';
	font-size: 1.125rem;
}

.token-create .ant-tabs-nav-list {
	width: 100%;
}

.token-create .ant-tabs-tab {
	width: 50%;
	background: white;
	border: 1px solid #ccc;
	border-radius: 25px;
	margin: 0 15px 0 15px;
}

.token-create .ant-tabs-tab-active {
	background-color: black !important;
	border-color: white !important;
}

.token-create .ant-tabs-tab-btn {
	text-align: center;
	width: 100%;
}

.token-create .ant-tabs-tab-active .ant-tabs-tab-btn {
	color: white !important;
	text-shadow: none !important;
}

.token-create .ant-tabs-ink-bar {
	background: none;
}

.ant-radio-button-wrapper-checked {
	background-color: black !important;
	border-color: white !important;
	color: white !important;
}

.ant-input,
textarea,
.ant-upload-drag,
.ant-input-number,
.ant-select {
	box-shadow: none;
	border-radius: 1rem;

	background-color: rgb(248, 248, 248);
}

.ant-input:focus,
.ant-input-number:focus {
	box-shadow: none;
	background: #fff;
}

.ant-input-number-handler-wrap {
	display: none;
}

.ant-input-number-input {
	outline: none;
}

.ant-form-item-control-input-content > .ant-select > .ant-select-selector {
	background: transparent;
	outline: none;
	border: none;
}

.ant-form-item-control-input-content > .ant-input-stripped {
	background: transparent;
}

.reputation-gradient {
	background: linear-gradient(266.87deg, #db7e64 -3.02%, #a39cc4 47.67%, #38d8c4 100.58%);
	background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.links-gradient {
	background: linear-gradient(266.87deg, #4595df -3.02%, #8373d0 27.19%, #fa5b38 64.96%, #fab838 100.58%);
	background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
}

.tokenAmount .ant-form-item-explain div {
	text-align: right;
	padding-right: 1rem;
}

@media screen and (max-width: 640px) {
	.search-item {
		max-width: 90vw;
	}
}

.btn-color-2 {
	background: linear-gradient(266deg, #8a4c4d -3.02%, #b6675e 17.73%, #cf857d 44.45%, #ac89b1 73.06%, #9c7166 98.42%);
}
.btn-color-3 {
	background: linear-gradient(266deg, #a08289 11.55%, #dfa08f 54.17%, #f8c880 98.42%);
}
.btn-color-4 {
	background: linear-gradient(266deg, #e4c062 -1.4%, #ed8e74 50.94%, #bcd6cb 86.01%);
}
.btn-color-5 {
	background: linear-gradient(266deg, #a4cebd 1.42%, #c98253 35.74%, #e78643 66.44%, #c96464 93.59%);
}
.btn-color-6 {
	background: linear-gradient(116deg, #b9afe8 14.41%, #da865b 52.34%, #bb4e75 85.99%);
}

.diag-gradient {
	background: linear-gradient(to bottom left, #8590b8 31.22%, #9992ba 41.47%, #ee967e 60.53%, #f4be7d 77.32%);
}

.text-gradient {
	-webkit-mask-image: linear-gradient(to bottom left, #81a9cf 0%, #a39cc4 21.35%, #df876f 65.63%, #f4c57d 98.96%);
	-webkit-box-decoration-break: clone;
	background-image: linear-gradient(
		to bottom left,
		#ce8484 6.41%,
		#cf6b6e 25.96%,
		#e78643 48.24%,
		#c98253 75.76%,
		#b66760 98.42%
	);
	background-clip: text;
	color: transparent;
}

.inactive-tab {
	background: #f4f4f4;
	color: #898989;
}

.disabled-text {
	color: #898989;
}

.Toastify__toast-container {
	font-family: 'Roobert';
	font-size: 13px;
}

.Toastify__toast--default {
	background: var(--color-0);
	color: #000;
	border-radius: 18px;
}

.Toastify__toast--dark {
	background: var(--color-0);
	color: #fcf9f2;
	border-radius: 18px;
}

.Toastify__toast--error {
	border-radius: 18px;
}

.Toastify__progress-bar--default,
.Toastify__progress-bar--dark {
	background: linear-gradient(266.87deg, #e8b05d -3.02%, #ed6f4d 26.11%, #7765cb 80.61%, #4f81e2 100.58%);
}

.text-gradient-3 {
	-webkit-mask-image: linear-gradient(to bottom left, #81a9cf 0%, #a39cc4 21.35%, #df876f 65.63%, #f4c57d 98.96%);
	-webkit-box-decoration-break: clone;
	background-image: linear-gradient(to bottom left, #a08289 11.55%, #dfa08f 54.17%, #f8c880 98.42%);
	background-clip: text;
	color: transparent;
}

.text-gradient-4 {
	-webkit-mask-image: linear-gradient(to bottom left, #81a9cf 0%, #a39cc4 21.35%, #df876f 65.63%, #f4c57d 98.96%);
	-webkit-box-decoration-break: clone;
	background-image: linear-gradient(to bottom left, #e4c062 -1.4%, #ed8e74 50.94%, #bcd6cb 86.01%);
	background-clip: text;
	color: transparent;
}

.text-gradient-5 {
	-webkit-mask-image: linear-gradient(to bottom left, #81a9cf 0%, #a39cc4 21.35%, #df876f 65.63%, #f4c57d 98.96%);
	-webkit-box-decoration-break: clone;
	background-image: linear-gradient(to bottom left, #a4cebd 1.42%, #c98253 35.74%, #e78643 66.44%, #c96464 93.59%);
	background-clip: text;
	color: transparent;
}

.text-gradient-6 {
	-webkit-mask-image: linear-gradient(to bottom left, #81a9cf 0%, #a39cc4 21.35%, #df876f 65.63%, #f4c57d 98.96%);
	-webkit-box-decoration-break: clone;
	background-image: linear-gradient(to bottom left, #b9afe8 14.41%, #da865b 52.34%, #bb4e75 85.99%);
	background-clip: text;
	color: transparent;
}

.text-gradient-7 {
	-webkit-mask-image: linear-gradient(to bottom left, #81a9cf 0%, #a39cc4 21.35%, #df876f 65.63%, #f4c57d 98.96%);
	-webkit-box-decoration-break: clone;
	background-image: linear-gradient(to bottom left, #8590b8 31.22%, #9992ba 41.47%, #ee967e 60.53%, #f4be7d 77.32%);
	background-clip: text;
	color: transparent;
}

.text-gradient-8 {
	-webkit-mask-image: linear-gradient(to bottom left, #81a9cf 0%, #a39cc4 21.35%, #df876f 65.63%, #f4c57d 98.96%);
	-webkit-box-decoration-break: clone;
	background-image: linear-gradient(266.87deg, #4595df 0%, #8373d0 29.17%, #fa5b38 65.63%, #fab838 100.58%);
	background-clip: text;
	color: transparent;
}

.bar-of-progress {
	background: linear-gradient(266.87deg, #e8b05d -3.02%, #ed6f4d 26.11%, #7765cb 80.61%, #4f81e2 100.58%);
}

*::-webkit-scrollbar {
	width: 7px;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

body::-webkit-scrollbar-track {
	background: var(--color-1);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--color-5);
	border-radius: 20px;
	border: 3px solid transparent;
}

.welcomeImage {
	background-color: #000;
	background-image: url('../public/images/welcome/welcomeBackground.svg');
	object-fit: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
}

@media screen and (max-width: 786px) {
	.welcomeImage {
		background: radial-gradient(
			120% 120% at 80% 10%,
			#ffca9c 0%,
			#ffab63 12.75%,
			#ef764b 35.94%,
			#c08283 58.22%,
			#7b779d 73.67%,
			#1a2c4c 100%
		);
		object-fit: cover;
	}
}

.logoImage {
	background-image: url('../public/images/logo.svg');
	background-repeat: no-repeat;
	object-fit: cover;
	height: 2rem;
	width: 168px;
	display: flex;
}

.underline-black {
	text-decoration: underline black;
}

.gradient-1 {
	background: linear-gradient(266.87deg, #e8b05d -3.02%, #ed6f4d 26.11%, #7765cb 80.61%, #4f81e2 100.58%);
}

.no-scroll-bar {
	&::-webkit-scrollbar {
		display: none; /* Safari */
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
}

// TODO: Need to resolve different color themes for different Libraries i.e Next theme and chakra ui
.chakra-ui-light {
	background: var(--color-1);
}
